<template>
  <div>
    <popup-remove
      title="แผนก"
      url="/System/Remove"
      column="system_id"
      :remove-id="dataPopup.system_id"
      :remove-name="dataPopup.system_name"
      @fetch-data="fncFetchDataTable"
    />
    <popup-detail :data="dataPopupDetail" />
    <div class="mb-1">
      <b-row>
        <b-col
          cols="12"
          md="6"
        >
          <button-add url="system/form" />
          <b-button
            variant="outline-primary"
            class="ml-1"
            :to="{ path: 'system/sort' }"
          >
            <!-- <feather-icon icon=""> -->
            จัดเรียงข้อมูล
          </b-button>
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <per-page-option
            :per-page.sync="perPage"
          />
        </b-col>
      </b-row>
    </div>
    <b-card no-body>
      <input-search :search.sync="searchQuery" />
      <pagination
        :item-local-to="itemLocalTo"
        :item-local-count="itemLocalCount"
        :total-data="totalData"
        :current-page.sync="currentPage"
        :per-page="perPage"
        show-detail
      />
      <table-simple
        :overlay-load-data-table="overlayLoadDataTable"
        :table-title="tableTitle"
        :total-data="dataTable.length"
      >
        <template slot="data">
          <b-tr
            v-for="item in dataTable"
            :key="item.system_id"
          >
            <b-td>
              {{ item.order }}
            </b-td>
            <b-td>
              {{ item.system_name }}
            </b-td>
            <b-td>
              {{ item.system_alias }}
            </b-td>
            <b-td>
              <b-badge variant="light-primary">
                {{ item.system_parent.length }}
              </b-badge>
            </b-td>
            <b-td>
              <b-dropdown
                variant="link"
                no-caret
                :right="$store.state.appConfig.isRTL"
              >

                <template #button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="align-middle text-body"
                  />
                </template>
                <b-dropdown-item
                  v-b-modal.modal-detail
                  @click="dataPopup = item"
                >
                  <feather-icon icon="FileTextIcon" />
                  <span class="align-middle ml-50">รายละเอียด</span>
                </b-dropdown-item>

                <b-dropdown-item :to="{ name: 'system-form-edit', params: { id: item.system_id } }">
                  <feather-icon icon="EditIcon" />
                  <span class="align-middle ml-50">แก้ไข</span>
                </b-dropdown-item>

                <b-dropdown-item
                  v-b-modal.modal-remove
                  @click="dataPopup = item"
                >
                  <feather-icon icon="TrashIcon" />
                  <span class="align-middle ml-50">ลบข้อมูล</span>
                </b-dropdown-item>
              </b-dropdown>
            </b-td>
          </b-tr>
        </template>
      </table-simple>
      <pagination
        :item-local-to="itemLocalTo"
        :item-local-count="itemLocalCount"
        :total-data="totalData"
        :current-page.sync="currentPage"
        :per-page="perPage"
      />
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BTr, BTd, BDropdown, BDropdownItem, BBadge, BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ButtonAdd from '../components/ButtonAdd.vue'
import PerPageOption from '../components/PerPageOption.vue'
import InputSearch from '../components/InputSearch.vue'
import TableSimple from '../components/TableSimple.vue'
import PopupRemove from '../components/PopupRemove.vue'
import PopupDetail from '../components/PopupDetail.vue'

import useSystem from './useSystem'
import Pagination from '../components/Pagination.vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BTr,
    BTd,
    BDropdown,
    BDropdownItem,
    BBadge,
    BButton,
    ButtonAdd,
    PerPageOption,
    InputSearch,
    TableSimple,
    PopupRemove,
    PopupDetail,
    Pagination,
  },

  directives: {
    Ripple,
  },

  setup() {
    const {
      // variable input
      search,

      // variable table
      currentPage,
      totalData,
      perPage,
      perPageOption,
      tableTitle,
      dataTable,
      searchQuery,
      itemLocalTo,
      itemLocalCount,
      dataPopup,

      // variable data
      dataFormPopup,

      // variable overlay
      overlayLoadDataTable,

      // variable computed
      dataPopupDetail,

      // function
      fncFetchDataTable,
    } = useSystem()

    return {
      // variable input
      search,

      // variable table
      currentPage,
      totalData,
      perPage,
      perPageOption,
      tableTitle,
      dataTable,
      searchQuery,
      itemLocalTo,
      itemLocalCount,
      dataPopup,

      // variable data
      dataFormPopup,

      // variable overlay
      overlayLoadDataTable,

      // variable computed
      dataPopupDetail,

      // function
      fncFetchDataTable,
    }
  },
}
</script>

<style scoped>
.table tbody td {
    vertical-align: middle;
    letter-spacing: 0.5px;
    height: 40px;
}
</style>
