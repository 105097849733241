import {
  ref, watch, onMounted, computed,
} from '@vue/composition-api'
import axios from '@axios'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useSystem() {
  // Notification
  const toast = useToast()

  // variable input
  const search = ref(null)

  // variable table
  const currentPage = ref(1)
  const totalData = ref(1)
  const perPage = ref(10)
  const perPageOption = [10, 25, 50, 100]
  const tableTitle = [
    { title: '#', style: 'min-width: 100px; width:100px;' },
    { title: 'ชื่อระบบ', style: 'min-width: 150px;' },
    { title: 'นามแฝง', style: 'min-width: 250px; width:250px;' },
    { title: 'ระบบย่อย', style: 'min-width: 120px; width:120px;' },
    { title: 'เครื่องมือ', style: 'min-width: 80px; width:80px;' },
  ]
  const searchQuery = ref(null)
  const dataTable = ref([])
  const itemLocalTo = ref(0)
  const itemLocalCount = ref(0)
  const dataPopup = ref({})

  // variable data
  const dataFormPopup = ref({})

  // variable overlay
  const overlayLoadDataTable = ref(false)

  // variable computed
  const dataPopupDetail = computed(() => {
    if (!Object.keys(dataPopup.value).length) return []

    const setDataPopupDetail = []
    setDataPopupDetail.push(
      { title: 'ชื่อระบบ', value: dataPopup.value.system_name },
      { title: 'นามแฝง', value: dataPopup.value.system_alias },
    )

    if (dataPopup.value.system_parent.length) setDataPopupDetail.push({ title: 'ระบบย่อย', value: '' })

    let x = 1
    dataPopup.value.system_parent.forEach(i => {
      setDataPopupDetail.push({ sub_title: `ชื่อระบบย่อย (${x})`, value: i.system_parent_name, sub_value: `(${i.system_parent_alias})` })
      x += 1
    })

    setDataPopupDetail.push(
      { title: 'create_date', value: dataPopup.value.create_date },
      { title: 'create_user', value: dataPopup.value.create_user },
      { title: 'update_date', value: dataPopup.value.update_date },
      { title: 'update_user', value: dataPopup.value.update_user },
    )

    return setDataPopupDetail
  })

  const fncFetchDataTable = () => {
    overlayLoadDataTable.value = true

    const payload = {
      search: searchQuery.value,
      per_page: perPage.value,
      page: currentPage.value,
    }

    axios
      .get('/System', { params: payload })
      .then(response => {
        const setDataTable = []
        Object.keys(response.data.value.data).forEach(key => {
          const item = response.data.value.data[key]
          setDataTable[key] = item
          // setDataTable[key].order = response.data.value.total - key - ((currentPage.value - 1) * perPage.value)
          setDataTable[key].order = Number(key) + 1 + ((currentPage.value - 1) * perPage.value)
        })

        dataTable.value = setDataTable

        totalData.value = response.data.value.total

        itemLocalTo.value = totalData.value - ((currentPage.value - 1) * perPage.value)
        itemLocalCount.value = totalData.value !== 0 ? itemLocalTo.value - dataTable.value.length + 1 : 0

        overlayLoadDataTable.value = false
      })
      .catch(error => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'แจ้งเตือน',
            text: `พบข้อผิดพลาด [${error}]`,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })

        overlayLoadDataTable.value = false
      })
  }

  onMounted(() => {
    fncFetchDataTable()
  })

  watch([searchQuery, currentPage, perPage], () => {
    fncFetchDataTable()
  })

  return {
    // variable input
    search,

    // variable table
    currentPage,
    totalData,
    perPage,
    perPageOption,
    tableTitle,
    dataTable,
    searchQuery,
    itemLocalTo,
    itemLocalCount,
    dataPopup,

    // variable data
    dataFormPopup,

    // variable overlay
    overlayLoadDataTable,

    // variable computed
    dataPopupDetail,

    // function
    fncFetchDataTable,
  }
}
