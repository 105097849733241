<template>
  <!-- Popup ลบข้อมูล -->
  <b-modal
    id="modal-remove"
    ref="refModalRemove"
    centered
  >
    <template #modal-title>
      <feather-icon icon="AlertCircleIcon" />
      <b> ยืนยันการลบข้อมูล</b>
    </template>

    <div class="modal-content-style mt-1 mb-1">
      คุณแน่ใจที่จะลบ{{ title }} &nbsp;<b>"{{ removeName }}"</b>&nbsp; ใช่หรือไม่?
    </div>

    <template #modal-footer>
      <div class="w-100">
        <b-overlay
          :show="overlaySubmit"
          rounded
          opacity="0.6"
          spinner-small
          spinner-variant="primary"
          class="d-inline-block"
        >
          <b-button
            class="float-left"
            variant="primary"
            @click="fncSubmit()"
          >
            <feather-icon icon="TrashIcon" />
            ลบข้อมูล
          </b-button>
        </b-overlay>
      </div>
    </template>
  </b-modal>
</template>

<script>
import {
  BButton, BOverlay,
} from 'bootstrap-vue'
import { ref } from '@vue/composition-api'
import axios from '@axios'

// notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BButton,
    BOverlay,
  },

  props: {
    title: {
      type: String,
      required: true,
    },

    url: {
      type: String,
      required: true,
    },

    column: {
      type: String,
      required: true,
    },

    removeId: {
      type: [String, Number],
      default: null,
    },

    removeName: {
      type: String,
      default: null,
    },
  },

  setup(props, { emit }) {
    // variable from import
    const toast = useToast()

    // variable ref
    const refModalRemove = ref(null)

    // variable overlay
    const overlaySubmit = ref(false)

    const resolveToastAlert = (action, error) => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'แจ้งเตือน',
          text: !error ? `${action}ข้อมูลสำเร็จ` : `พบข้อผิดพลาดในการ${action}ข้อมูล [${error}]`,
          icon: !error ? 'CheckCircleIcon' : 'AlertTriangleIcon',
          variant: !error ? 'success' : 'danger',
        },
      })
    }

    const fncSubmit = () => {
      overlaySubmit.value = true

      const payload = {
        [`${props.column}`]: props.removeId,
      }

      return new Promise((resolve, reject) => {
        axios
          .put(`${props.url}`, { requestData: payload })
          .then(response => {
            if (response.data.status) {
              resolveToastAlert('ลบ')

              refModalRemove.value.hide()
              emit('fetch-data')
            } else resolveToastAlert('ลบ', response.data.message)

            overlaySubmit.value = false
            resolve(response)
          })
          .catch(error => {
            resolveToastAlert('ลบ', error)
            overlaySubmit.value = false

            reject(error)
          })
      })
    }

    return {
      // variable overlay
      overlaySubmit,

      // variable ref
      refModalRemove,

      // function
      fncSubmit,
    }
  },
}
</script>

<style>

</style>
