<template>
  <!-- Popup ลบข้อมูล -->
  <b-modal
    id="modal-detail"
    hide-footer
    centered
    :size="size"
  >
    <template #modal-title>
      <feather-icon icon="AlertCircleIcon" />
      <b> รายละเอียดข้อมูล</b>
    </template>
    <div>
      <b-row
        v-for="(item, index) in data.filter(i => !arrDefaultData.includes(i.title))"
        :key="index"
      >
        <b-col>
          <span
            v-if="item.title"
            class="font-weight-bolder"
          >
            {{ item.title }}
          </span>
          <span
            v-if="item.sub_title"
            class="ml-2"
          >
            {{ item.sub_title }}
          </span>
        </b-col>
        <b-col>
          {{ item.value }}
          <small
            v-if="item.sub_value"
            class="d-block"
          >
            {{ item.sub_value }}
          </small>
        </b-col>
      </b-row>
    </div>
    <template v-if="resolveLastActive">
      <hr>
      <b-row>
        <b-col cols="12">
          <small class="d-block">ผู้เพิ่มข้อมูล : {{ resolveLastActive.create }}</small>
          <small class="d-block">ผู้อัพเดทข้อมูล : {{ resolveLastActive.update }}</small>
        </b-col>
      </b-row>
    </template>
  </b-modal>
</template>

<script>
import {
  BRow, BCol,
} from 'bootstrap-vue'
import { computed } from '@vue/composition-api'

export default {
  components: {
    BRow,
    BCol,
  },
  props: {
    data: {
      type: Array,
      required: true,
    },

    size: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const arrDefaultData = ['create_date', 'create_user', 'update_date', 'update_user']

    const resolveLastActive = computed(() => {
      const indexCreteDate = props.data.findIndex(f => f.title === 'create_date')
      const indexCreteUser = props.data.findIndex(f => f.title === 'create_user')
      const indexUpdateDate = props.data.findIndex(f => f.title === 'update_date')
      const indexUpdateUser = props.data.findIndex(f => f.title === 'update_user')

      // eslint-disable-next-line eqeqeq
      if (indexCreteDate != -1 && indexCreteUser != -1 && indexUpdateDate != -1 && indexUpdateUser != -1) {
        const create = `${props.data[indexCreteUser].value} (${props.data[indexCreteDate].value})`
        const update = props.data[indexUpdateDate].value ? `${props.data[indexUpdateUser].value} (${props.data[indexUpdateDate].value})` : '-'
        return {
          create,
          update,
        }
      }

      return null
    })

    return {
      arrDefaultData,
      resolveLastActive,
    }
  },
}
</script>

<style>

</style>
