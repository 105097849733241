<template>
  <div class="d-flex align-items-center justify-content-end">
    <b-dropdown
      :text="`แสดง ${perPage} ${$store.state.app.windowWidth > 540 ? 'รายการ' : ''} / หน้า`"
      variant="outline-primary"
    >
      <b-dropdown-item
        v-for="option in dataPerPageOption"
        :key="option"
        @click="$emit('update:per-page', option)"
      >
        {{ option }}
      </b-dropdown-item>
    </b-dropdown>
  </div>
</template>

<script>
import {
  BDropdown, BDropdownItem,
} from 'bootstrap-vue'

export default {
  components: {
    BDropdown,
    BDropdownItem,
  },

  props: {
    perPage: {
      type: Number,
      required: true,
    },
  },

  setup() {
    // variable data
    const dataPerPageOption = [10, 25, 50, 100]

    return {
      dataPerPageOption,
    }
  },
}
</script>

<style>

</style>
