<template>
  <b-button
    variant="primary"
    :to="{ path: url }"
  >
    <feather-icon
      v-if="icon"
      :icon="icon"
    />
    {{ title }}
  </b-button>
</template>

<script>
import {
  BButton,
} from 'bootstrap-vue'

export default {
  components: {
    BButton,
  },

  props: {
    icon: {
      type: String,
      default: null,
    },

    url: {
      type: String,
      default: null,
    },
    title: {
      type: String,
      default: 'เพิ่มข้อมูล',
    },
  },
}
</script>
